import * as React from 'react';

import Layout from '../components/Layout';
import GatsbySEO from '../components/GatsbySEO';
import Content from '../components/Content';
import Title from '../components/Title';

const IndexPage = () => (
  <Layout>
    <GatsbySEO title="Coming Soon | Thomas Michael Rutter" pathname="/" />
    <Title>Coming Soon</Title>
    <Content>
      <p>
        ThomasMichaelRutter.com is currently under construction, but it will be
        live shortly.
      </p>
      <p>Please try back again soon!</p>
    </Content>
  </Layout>
);

export default IndexPage;
